<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            <div class="d-flex justify-content-between">
              <p class="mb-0 font-weight-bold">Publicidad</p>
            </div>
          </CCardHeader>
          <CCardBody>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <CRow v-if="publicidad">
              <CCol col="12" xl="6">
              	<p class="mb-0"><b>Título:</b> {{publicidad.title}}</p>
              	<p class="mb-0"><b>Descripción:</b> {{publicidad.description}}</p>
              	<p class="mb-0"><b>Inicio:</b> {{publicidad.start}}</p>
              	<p class="mb-0"><b>Fin:</b> {{publicidad.end}}</p>
              	<p class="mb-0"><b>Link destino:</b> {{publicidad.data.link}}</p>
              	<p class="mb-0"><b>Tipo:</b> {{publicidad.data.type === 0 ? 'Imagen':'Video'}}</p>
              	<img v-if="publicidad.data.type === 0" :src="publicidad.data.url" width="400" height="250" style="object-fit: contain;" />
              	<video v-else :src="publicidad.data.url" width="400" height="250" controls></video>
              </CCol>
              <CCol col="12" xl="6">
                <div class="d-flex justify-content-center align-items-center text-center py-2 bg-primary text-white rounded-top">
                  <p class="mb-0 ">Usuarios que hicieron click</p>
                </div>
                <CDataTable
                  v-show="!loading && publicidad.users_clicked.length > 0"
                  hover
                  striped
                  :items="publicidad.users_clicked"
                  :fields="fields"
                  index-column
                >
                  <template #user="data">
                    <td>{{data.item.user.name}} <small>({{data.item.user.email}})</small></td>
                  </template>
                  <template #clicked="data">
                    <td>{{data.item.created_at}}</td>
                  </template>
                </CDataTable>
                <div class="text-center font-weight-bold" v-show="!loading && publicidad.users_clicked.length === 0">Nadie ha hecho click aún</div>
              </CCol>
            </CRow>

          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import { getPublicidadById } from '../../api/publicidad'

export default {
  name: 'PublicidadData',
  data: () => {
    return {
      loading: true,
      alerta: null,
      publicidad: null,
      fields: [
        {key: 'user', label: 'Usuario'},
        {key: 'clicked', label: 'Fecha click'}
      ]
    }
  },
  mounted(){
    this.getPublicidad(this.$route.params.id)
  },
  methods: {
    formatPosition(position){
      const geo = {
        lat: position.latitude,
        lng: position.longitude
      }
      return geo
    },
    closeModal(){
      this.showModal = false
      this.getUsers()
    },
    getPublicidad(id){
      const data = { id }
      const self = this
      this.loading = true
      getPublicidadById(data)
        .then(function(resp){
          self.publicidad = resp.data
          self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getBadge (status) {
      const info = {
          0: 'success',
          1: 'primary',
          2: 'primary',
          3: 'warning',
          4: 'warning',
          5: 'warning',
          6: 'warning',
          7: 'warning'
      }
      return info[status]
    },
    getEstado (status) {
      const info = {
          0: 'Cerrada en conformidad',
          1: 'Cerrada por inactividad',
          2: 'Cerrada por nula comunicación',
          3: 'Reabierta',
          4: 'Envío de ayuda a dirección',
          5: 'Comienzo alarmas en establecimiento',
          6: 'Comienzo Monitoreo',
          7: 'Término Monitoreo'
      }
      return info[status]
    }
  }
}
</script>